.css{

}

@media screen and (min-width:1920px) {
  .css{
    left: 220px !important;
  }
}

@media screen and  (max-width: 650px){
  .css{
    display: none;
  }
}
