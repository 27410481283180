

.create_link_page{
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  height: 240px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
