
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-linear{
    background: linear-gradient(to right, #FF067A 0%, #FF6E1F 100%);
  }
}


.bg-linear{
  background: linear-gradient(to right, #FF067A 0%, #FF6E1F 100%);
}

.text-linear{
  background: linear-gradient(to right, #FF067A 0%, #FF6E1F 100%);

  /* 将背景裁剪到文字区域 */
  -webkit-background-clip: text;
  background-clip: text;

  /* 设置文字颜色为透明，以便显示背景渐变 */
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

* {
  margin: 0;
  padding: 0;
}
body {
  /*font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;*/
  /* font-family: Segoe UI, Segoe UI; */
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
}

.font_noto{
  font-family: Noto Sans SC;
}

:root{
    --primary-color: #FA6400;
    --primary-color-80: rgba(250, 100, 0, 0.8);
    --ansytarr-primary-color: #F0774A
}

a{
  text-decoration: none !important;
}

.header_container {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  height: 80px;
  background-color: #ffffff;
  z-index: 999;
  width:100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
}
.logo-container {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 20px; */
  padding-left: 24px;
  cursor: pointer;
}

.sys-name {
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
}
.logo {
  width: 130px;
  height: 42px;
}
@media screen and (max-width: 650px){
  .logo-container{
    padding-left: 16px;
  }
  .logo {
    width: 99px;
    height: 32px;
  }
}
.right-container {
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 650px){
  .right-container {
    right: 0px;

    /* .avatar{
     border-radius: 50px;
    } */
  }

}

.search_icon{
  width: 24px;
  height: 24px;
  margin-right: 25px;
  cursor: pointer;
}


.global_search{

}
.global_search .ant-modal .ant-modal-content{
  background: #00000000 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.search_warp{
  width: 720px;
   height: 52px;
  /*height: 50px;*/
  background: #F6F6F6;
  border-radius: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all .3s;
  border: 3px solid transparent;
}

.high_light {
  color: #FA6400;
}

.search_warp:hover{
  border: 2px solid transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
  linear-gradient( to right, #FF067A, #FF6E1F);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.search_warp:focus-within{
  border: 2px solid transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
  linear-gradient( to right, #FF067A, #FF6E1F);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.border_search{
  border: 2px solid transparent;
  background-image: linear-gradient(#ffffff, #ffffff),
  linear-gradient( to right, #FF067A, #FF6E1F);
  background-origin: border-box;
  background-clip: content-box, border-box;
}


.ant-cascader-dropdown .ant-cascader-menu{
  height: 300px;
}

.ant-select-dropdown{
  z-index: 666;
}

.ant-dropdown{
  z-index: 666;
}

@media screen and (max-width: 650px){
  .search_warp{
    width: 100%;
    height: 40px !important;
    margin: 0 auto;
    zoom: 1;
  }
}

.m_wrap{

}
@media screen and (max-width: 650px){
  .m_wrap{
    /*padding: 0 40px;*/
    padding: 0 15px;
  }
}

.search_warp .left_wrap{
  width: 190px;
  height: 57px;
  border-radius: 40px 0px 0px 40px;
  box-shadow: none !important;
  border: none!important;
  font-size: 16px;
}

@media screen and (max-width: 650px){
  .search_warp .left_wrap{
    width: 120px;
    height: 40px !important;
    font-size: 12px !important;
  }
}


.overflow{
  overflow: hidden !important;
}

.left_wrap .ant-select-selector{
  border: 0 !important;
  box-shadow: none !important;
  background: #00000000  !important;
  font-size: 16px;
}
.left_wrap .ant-select-single{
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 16px;
}

@media screen and (max-width: 650px){
  .left_wrap .ant-select-single{
    padding: 0 5px;
  }
}

.search_warp .right_wrap{
  /*background: #F0774A;*/
  border-radius: 0px 40px 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.right_wrap img{
  object-fit: contain;
}

.search_warp .input{
  flex: 1;
  border: none !important;
  outline: 0 !important;
  outline-color: #00000000 !important;
  border-color: #00000000 !important;
  background: #00000000;
  box-shadow: none !important;
  font-size: 16px;
  padding: 0 20px;
  height: 100%;
}

.search_warp .input::placeholder{
  color: #D3D3D3;
}

@media screen and (max-width: 650px){
  .search_warp .input{
    padding: 0 10px;
  }
}


.user_name_wrap{
    font-size: 16px;
    font-weight: 500;
}

.font_hover{
  transition: all .3s;
}

.font_hover:hover{
  color: var(--primary-color) !important;
}

.pop_content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop_content div{
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    transition: all .3s;
    padding: 0 20px;
    font-weight: bold;
}

.pop_content div:hover{
    background: #33333310;
    color: var(--primary-color-80);
}
.sub_page_title{
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  z-index: -1;
}

.back-icon{
  background: url("../src/imgs/header/back_icon.webp") no-repeat;
  width: 24.57px;
  height: 15.57px;
  margin-left: 20px;
  background-size: cover;
}

.text_ellipsis {
  white-space: nowrap;          /* 不允许文本换行 */
  overflow: hidden;             /* 隐藏超出范围的文本 */
  text-overflow: ellipsis;      /* 显示省略号 */
}

.address_show_wrap{
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 36px;
}

.link_wallet{
  background: linear-gradient(to right, #FF067A 0%, #FF6E1F 100%);
  padding: 0 20px;
  height: 36px;
  display: flex;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.flex{
  display: flex;
}

.items-center{
  align-items: center;
}

.just-center{
  justify-content: center;
}


@media screen and (max-width: 650px){
  .link_wallet{
    width: 100%;
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    margin-right: 16px;
  }
}

.language-btn{
  background: url("../src/imgs/header/global-icon.svg") no-repeat;
    background-color: #FF0167;
    border-radius: 12px;
  width: 24px;
  height: 24px;
  margin: 0 10px;
}

.application-btn{
  background: url("../src/imgs/header/application-icon.svg") no-repeat;
  width: 24px;
  height: 24px;
}

.ant-drawer-body{
  background: #030406;
  font-weight: 400;
  color: #FFFFFF;
  font-size: 16px
}

.header-drawer-top-logo{
  background: url("../src/imgs/header/home_drawer_logo.webp") no-repeat;
  width: 151px;
  height: 74px;
  background-size: cover;
  margin: 0 auto 70px 0;
}

.flex_div_wrap{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logout_icon{
  background: url("../src/imgs/header/logout_icon.webp") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
  margin-left: 10px;
}
.store_icon{
  background: url("../src/imgs/header/store_icon.webp") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
  margin-left: 10px;
}
.short_icon{
  background: url("../src/imgs/header/short_icon.png") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
  margin-left: 10px;
}
.short_video_icon{
  background: url("../src/imgs/header/short_video_icon.png") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
  margin-left: 10px;
}
.chat_icon{
  background: url("../src/imgs/header/chat_icon.png") no-repeat;
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
  margin-left: 10px;
}

.select-language-wrap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.select-language-wrap div:first-child{
  padding: 0 0 15px 0;
}
.select-language-wrap div{
  padding: 15px 0;
}
.select-language-wrap div{
  padding: 15px 0;
}
.select-language-wrap div:last-child{
  padding: 15px 0 0 0;
}


.ant-modal-body{

}

.ant-modal-header{
  background: #ffffff;
  color: #000000;
  border-radius: 20px;
}

.ant-modal-title{
  color: #000000;
}
.ant-modal-close{
  color: #000000;
}
.ant-modal-content{
  background: #ffffff;
  border-radius: 20px;
}
.ant-modal-content p{
  color: #000000;
}
.ant-modal-footer .ant-btn{
  background: #ffffff;
  color: #000000;
}

.ant-modal-footer .ant-btn-primary{
  background: linear-gradient(to right, #9D26F7, #314AF0);
  border: none;
}


.animated {
    animation-duration: 2s;
    animation-fill-mode: both;
}

.fadeIn {
    /* animation-name: fadeIn; */
    /* animation: fadeIn .5s ease-in-out; */
}

.animate__fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}

/* .ant-popover-inner-content{
    padding: 6px;
} */

.customPopover .ant-popover-inner-content{
  color: white !important;
}

@keyframes fadeIn {
    from {
        opacity: .6;
    }

    to {
        opacity: 1;
    }
}

*::-webkit-scrollbar{
    display: none;
}




.app-footer {
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  z-index: 99;
}
.app-footer .footer-gray {
  /* background-color: rgb(245,242,240); */
  background-color: #222222;
  position: relative;
}
.app-footer .footer-black {
  background-color: #111114;
  text-align: center;
  color: #fff !important;
  padding: 5px 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 650px){
  .app-footer{
    zoom:0.7
  }
}

.footer-black a{
  color: #fff !important;
}

.footer-gray .footer-g-content {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  align-items: center;
}

.footer-g-content a{
  color:#fff
}

.footer-link-2 {
  margin: 0 20px;
  font-size: 14px;
}
@media screen and (max-width: 650px){
  .footer-link-2{
   margin: 0 15px;
  }
}

.footer-g-content .cont-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-left .logo-ab {
  height: 18px;
}
.cont-left .ic-sx {
  height: 30px;
  margin: 0 10px;
}
.cont-left .logo-1 {
  height: 40px;
}
.cont-right {
  display: flex;
  align-items: center;
}
.cont-right .follow-us {
  font-size: 14px;
  margin-right: 10px;
  color: #fff;
}
.linkedin-link, .ins-link {
  display: inherit;
  margin-right: 10px;
}
.cont-right .logo-fb {
  height: 35px;
  margin: 0 5px;
}
.cont-right .logo-google {
  height: 35px;
}

.cont-right .ins-logo-footer {
  color: #707a8a;
  font-size: 17px;
}
.cont-right .linkin-logo-footer {
  color: #707a8a;
  font-size: 17px;
}
.cont-right .ins-logo-footer:hover {
  color: #fff;
}
.cont-right .linkin-logo-footer:hover {
  color: #fff;
}

html{
  -webkit-text-size-adjust: none;
}


.custom_check_icon{
  box-sizing: border-box;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
}

.custom_check_icon:hover{
  border: 1px solid #F0774A;
}

.custom_checked_icon{
  background-color: #f0774a;
  border-color: #f0774a;
}

.custom_checked_icon::after{
  opacity: 1;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  inset-inline-start: 25%;
  display: table;
  width: 5.7142857142857135px;
  height: 9.142857142857142px;
  border: 2px solid #fff;
  border-top: 0;
  border-inline-start: 0;
  content: "";
}



.text_222{
  color: #222222;
}

.text_a7{
  color: #A7A7A7;
}

.text_8f{
  color: #8F8C8C;
}

.text_FF6{
  color: #FF6E1F !important;
}

.text_red{
  color: #f00;
}


.ant-tour .ant-tour-inner{
  background: #00000000;
}

.ant-tour .ant-tour-inner .ant-tour-footer{
  display: none !important;
}


@media screen and (max-width: 650px) {
    .header_container{
        width: 100%;
        padding: 14px 0px;
        height: 60px;
    }
}



/* 容器样式 */
.my_masonry_grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -12px; /* gutter size offset */
  width: auto;
}

/* 列样式 */
.my_masonry_grid_column {
  padding-left: 12px; /* 每列的内边距 */
  background-clip: padding-box; /* 修复背景裁切问题 */
}

@media screen and (min-width:1920px) {
  .my_masonry_grid {
    margin-left: -24px; /* gutter size offset */
  }
  .my_masonry_grid_column {
    padding-left: 24px; /* 每列的内边距 */
  }
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}


.ant-collapse>.ant-collapse-item >.ant-collapse-header{
  padding: 0;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-header-text{
  margin-inline-end: unset;
  flex: unset;
}
.ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box{
  padding-block: 0;
  padding: 0;
}



@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.popup-modal-enter {
  animation: slideUp 0.3s ease-out forwards;
}

.popup-modal-exit {
  animation: slideDown 0.3s ease-in forwards;
}
