:root {
  // 主⾊
  --primary: #FF6E1F;
  // 主⾊2
  --primary2: #FF067A;


  // 渐变色
  --gradient-color: var(--Linear, linear-gradient(88deg, #FF067A 0%, #FF6E1F 100%));

  //标题
  --text-title: #222;
  //次要⽂字
  --text-primary: #4E4E4E;
  // ⼀般⽂字
  --text-secondary: #7A7A7A;
  // 较弱⽂字
  --text-disable: #a7a7a7;
  // 按钮文字
  --text-button: #FFF;

  // 状态文字1
  --text-status1: #2BAD3A;
  // 状态文字2
  --text-status2: #F04A4A;


  // 背景色1  删除icon
  --background1: #D3D3D3;
  // 背景色2
  --background2: #EDEDED;
  // 背景色3
  --background3: #F6F6F6;
  // 背景色4 浅橙
  --background4: #FFF3ED;

  // 状态背景色1 浅绿
  --background-status1: #EAF7EC;
  // 状态背景色2 浅红
  --background-status2: #FEEDED;


  // 暂未用到

  //橙色2
  --orange2: #FF8B4C;
  //橙色3
  --orange3: #FFA879;
  //橙色4
  --orange4: #FFC5A5;
  //橙色5
  --orange5: #FFE2D2;
  //橙色6
  // --orange6: #FFF3ED;
  //橙色7
  --orange7: #FFF9F6;

  //红色2
  --red2: #F36E6E;
  //红色3
  --red3: #F69292;
  //红色4
  --red4: #F9B7B7;
  //红色5
  --red5: #FCDBDB;
  //红色7
  --red7: #FEF6F6;

  //粉色1
  --pink1: #FF067A;
  //粉色2
  --pink2: #FF3895;
  //粉色3
  --pink3: #FF6AAF;
  //粉色4
  --pink4: #FF9BCA;
  //粉色5
  --pink5: #FFCDE4;
  //粉色6
  --pink6: #FFEBF4;
  //粉色7
  --pink7: #FFF5FA;

  //浅蓝色1
  --lightblue1: #00F2EA;
  //浅蓝色2
  --lightblue2: #33F5EE;
  //浅蓝色3
  --lightblue3: #66F7F2;
  //浅蓝色4
  --lightblue4: #99FAF7;
  //浅蓝色5
  --lightblue5: #CCFCFB;
  //浅蓝色6
  --lightblue6: #EBFEFD;
  //浅蓝色7
  --lightblue7: #F5FEFE;

  //绿色2
  --green2: #55BD61;
  //绿色3
  --green3: #80CE89;
  //绿色4
  --green4: #AADEB0;
  //绿色5
  --green5: #D5EFD8;
  //绿色7
  --green7: #F4FBF5;

  //蓝色
  --blue1: #4296D6;
  //蓝色2
  --blue2: #68ABDE;
  //蓝色3
  --blue3: #8EC0E6;
  //蓝色4
  --blue4: #B3D5EF;
  //蓝色5
  --blue5: #D9EAF7;
  //蓝色6
  --blue6: #EDF5FB;
  //蓝色7
  --blue7: #EDF5FB;
}
