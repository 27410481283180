.SearchDropDown {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 665;
  .dropDown {
    //border: 1px solid #F0774A;
    box-shadow: 0px 2px 6px 0px rgba(221,221,221,0.62);
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    top: 100px;
    width: 720px;
    padding: 10px;
    color: #999999;
    z-index: 665;

    .dropDownItem {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      margin: 5px 0;
      padding: 10px;
      border-radius: 10px;
      &:hover {
        background-color: #F3F3F3;
        color: black;
      }
    }

    .no_hover{
      background-color: unset !important;
    }

    @media (max-width: 640px) {
      margin: 0 20px;
      border-radius: 30px;
    }
  }
}
