.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 99999;
  display: flex;
  align-items: flex-end;

  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .popup-enter {
    animation: slideUp 0.3s ease-out forwards;
  }

  .popup-exit {
    animation: slideDown 0.3s ease-in forwards;
  }

  .popup {
    background: var(--text-button);
    width: 100%;
    border-radius: 6px 6px 0 0;
    position: fixed;
    bottom: 0;

    .header {
      padding: 16px;
      padding-left: 24px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid var(--background2);

      .headerLeft {
        // width: 10px;
        color: var(--text-title);
        font-size: 24px;
        line-height: 142%;
        font-weight: 700;
      }

      .headerRight {
        // font-size: 20px;
        width: 20px;
        height: 20px;
        color: var(--text-disable);
      }
    }

    .content {
      padding: 0px 20px 0 40px;

      .firstTitle {
        list-style-type: disc;
        font-size: 14px;
        color: var(--text-title);
        line-height: 150%;
      }

      .subTitle {
        list-style-type: disc;
        margin-left: 16px;
      }

      .diffText {
        color: var(--primary);
        font-weight: 700;
      }
    }

    .footer {
      // width: 100%;
      background: var(--gradient-color);
      margin: 0 16px 16px 24px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;
      color: var(--text-button);

      .footerReset {
        flex: 1;
        border-top: 1px solid var(--background2);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-title);
      }

      .footerConfirm {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gradient-color);
        padding: 8px 0;
        color: var(--text-button);
      }
    }
  }

}
