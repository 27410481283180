.moreModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.moreModal {
  position: fixed;
  width: 100%;
  top: 60px;
  // height: 100px;
  background: #fff;
  box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.1), 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--background2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
